import Config from "@/utils/config"
import {
	login
} from "@/api/auth/login"
import {
	adList
} from "@/api/website"
import {
	noticesList
} from "@/api/cms/notice"
import {
	floors,
	floatLayer,
	apiDefaultSearchWords
} from "@/api/pc"
import {
	mapGetters
} from "vuex"
import {
	couponList,
	receiveCoupon
} from "@/api/coupon"
import {
	goodsPage,
	timeList
} from "@/api/seckill"
import {
		tree,
		categoryConfig
	} from '@/api/goods/goodscategory';
import {goodsHomeList,homeinfodata} from '@/api/goods/goods';
import CountDown from "vue2-countdown";

export default {
	name: "index",
	components: {
		CountDown
	},
	data: () => {
		return {
			loadingAd: true,
			loadingFloor: true,
			adList: [],
			adLeftList: [],
			adRightList: [],
			couponList: [],
			floorList: [],
			floatLayer: {
				is_show: false,
				link: {
					url: ""
				}
			},
			indexFloatLayerNum: 0,
			isSub: false,
			siteId: 0,
			listData: [],
			seckillTimeMachine: {
				currentTime: 0,
				startTime: 0,
				endTime: 0
			},
			seckillText: "距离结束",
			backgroundColor: "", // 顶部banner背景颜色
			// 悬浮搜索
			searchType: "goods",
			searchTypeText: "商品",
			keyword: "",
			defaultSearchWords: "",
			isShow: false,
			goodsCategoryTree:[],
			goodsCategoryAll:[],
			activeName:'gametab_999',
			goodsHomeListArr:[],
			activeGoodsName:'goodstab_0',
			homeinfodataArr:[],
			fenlei_index:0,
			zhanghao_fenlei_index:0,
			
		}
	},
	watch: {
		searchType() {
			this.searchTypeText = this.searchType == "goods" ? "商品" : "店铺"
		}
	},
	created() {
		this.getAdList()
		this.getBigAdList()
		this.getSmallAdList()
		this.getFloors()
		this.getFloatLayer()
		this.$store.dispatch("site/siteInfo") // 站点信息
		this.getDefaultSearchWords() // 默认搜索类型
		if(this.addonIsExit && this.addonIsExit.seckill == 1){
			this.getTimeList()
		}		
		this.getCanReceiveCouponQuery()
		
		this.getCategoryConfig();//分类获取
		this.goodsHomeList();//顶级账号获取
		this.getHomeInfoData();//获取首页数据
	},
	mounted() {
		window.addEventListener("scroll", this.handleScroll)
	},
	computed: {
		...mapGetters(["defaultHeadImage", "addonIsExit", "defaultGoodsImage", "member", "siteInfo", "cartCount"]),
		optionLeft() {
			return {
				direction: 2,
				limitMoveNum: 2
			}
		}
	},
	methods: {
		go(item){
		    const routeData = this.$router.resolve({
		           path: '/goodsdetail',
		           query:{
		            id:item.sku_id
		           }
		         });
		         window.open(routeData.href, '_blank');
				 setTimeout('history.go(-1);',10);				 
		    },
		countDownS_cb() {},
		countDownE_cb() {
			this.seckillText = "活动已结束"
		},
		getAdList() {
			adList({
					keyword: "NS_PC_INDEX"
				})
				.then(res => {
					console.log(res,'getAdList')
					this.adList = res.data.adv_list
					for (let i = 0; i < this.adList.length; i++) {
						if (this.adList[i].adv_url) this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url)
					}
					this.backgroundColor = this.adList[0].background
					this.loadingAd = false
				})
				.catch(err => {
					this.loadingAd = false
				})
		},
		handleChange(curr, pre) {
			this.backgroundColor = this.adList[curr].background
		},
		
		fenleiChange(e){
			this.fenlei_index=e.index;
		},
		/**
		 * 广告位大图
		 */
		getBigAdList() {
			adList({
					keyword: "NS_PC_INDEX_MID_LEFT"
				})
				.then(res => {
					this.adLeftList = res.data.adv_list
					for (let i = 0; i < this.adLeftList.length; i++) {
						if (this.adLeftList[i].adv_url) this.adLeftList[i].adv_url = JSON.parse(this.adLeftList[i].adv_url)
					}
					this.loadingAd = false
				})
				.catch(err => {
					this.loadingAd = false
				})
		},
		/**
		 * 广告位小图
		 */
		getSmallAdList() {
			adList({
					keyword: "NS_PC_INDEX_MID_RIGHT"
				})
				.then(res => {
					this.adRightList = res.data.adv_list
					for (let i = 0; i < this.adRightList.length; i++) {
						if (this.adRightList[i].adv_url) this.adRightList[i].adv_url = JSON.parse(this.adRightList[i].adv_url)
					}
					this.loadingAd = false
				})
				.catch(err => {
					this.loadingAd = false
				})
		},
		//获取优惠券列表
		getCanReceiveCouponQuery() {
			couponList({
					activeName: "second",
					site_id: this.siteId
				})
				.then(res => {
					console.log(res,'获取优惠券列表')
					let data = res.data
					this.couponList = []
					if (data != null) {
						for (let i = 0; i < data.list.length; i++) {
							if (i < 5) {
								this.couponList.push(data.list[i])
							}
						}
						this.couponList.forEach(v => {
							v.useState = 0
						})
					}
				})
				.catch(err => {})
		},
		/**
		 * 领取优惠券
		 */
		receiveCoupon(item, index) {
			console.log(item, index)
			if (this.isSub) return
			this.isSub = true

			var data = {
				site_id: item.site_id,
				activeName: "second",
				coupon_type_id: item.coupon_type_id
			}
			receiveCoupon(data)
				.then(res => {
					console.log(res,'领取优惠券')
					var data = res.data
					let msg = res.message
					if (res.code == 0) {
						msg = "领取成功"
						this.$message({
							message: msg,
							type: "success"
						})
					} else {
						console.log('领取优惠券')
						this.$message({
							message: msg,
							type: "warning"
						})
					}
					let list = this.couponList
					if (res.data.is_exist == 1) {
						for (let i = 0; i < list.length; i++) {
							if (list[i].coupon_type_id == item.coupon_type_id) {
								list[i].useState = 1
							}
						}
					} else {
						for (let i = 0; i < list.length; i++) {
							if (list[i].coupon_type_id == item.coupon_type_id) {
								list[i].useState = 2
							}
						}
					}

					this.isSub = false
					this.$forceUpdate()
				})
				.catch(err => {
					if( err.message == '您尚未登录，请先进行登录' ){
						this.$router.push('/login');
					} else {
						this.$message.error(err.message)
					}
					this.isSub = false
				})
		},
		/**
		 * 点击优惠券
		 */
		couponTap(item, index) {
			if (item.useState == 0) this.receiveCoupon(item, index)
			else this.toGoodsList(item)
		},
		/**
		 * 去购买
		 */
		toGoodsList(item) {
			if (item.use_scenario != 1) {
				this.$router.push({
					path: "/list",
					query: {
						platformcouponTypeId: item.platformcoupon_type_id
					}
				})
			} else {
				this.$router.push("/list")
			}
		},
		/**
		 * 限时秒杀
		 */
		getTimeList() {
			timeList()
				.then(res => {
					if (res.code == 0 && res.data) {
						let time = new Date(res.timestamp * 1000)
						let currentTimes = time.getHours() * 60 * 60 + time.getMinutes() * 60 + time.getSeconds()

						res.data.list.forEach((v, k) => {
							if (v.seckill_start_time <= currentTimes && currentTimes < v.seckill_end_time) {
								let seckillId = v.seckill_id
								this.getGoodsList(seckillId)

								let endTime = parseInt(time.getTime() / 1000) + (v.seckill_end_time - currentTimes)
								this.seckillTimeMachine = {
									currentTime: res.timestamp,
									startTime: res.timestamp,
									endTime: endTime
								}
							}
						})
					}
				})
				.catch(err => {
					// this.$message.error(err.message)
				})
		},
		/**
		 * 秒杀商品
		 */
		getGoodsList(id) {
			goodsPage({
					page_size: 0,
					seckill_id: id,
					site_id: this.siteId
				})
				.then(res => {
					if (res.code == 0 && res.data.list) {
						this.listData = res.data.list
					}
				})
				.catch(err => {})
		},
		/**
		 * 图片加载失败
		 */
		imageError(index) {
			this.listData[index].sku_image = this.defaultGoodsImage
		},
		/**
		 * 图片加载失败
		 */
		adLeftImageError(index) {
			this.adLeftList[index].adv_image = this.defaultGoodsImage
		},
		/**
		 * 图片加载失败
		 */
		adRightImageError(index) {
			this.adRightList[index].adv_image = this.defaultGoodsImage
		},
		getFloors() {
			floors()
				.then(res => {
					this.floorList = res.data;
				})
				.catch(err => {
					console.log(err)
				})
		},
		getFloatLayer() {
			floatLayer()
				.then(res => {
					if (res.code == 0 && res.data) {
						this.floatLayer = res.data	
						if(this.floatLayer.is_show == 1){
							this.floatLayer.link = JSON.parse(this.floatLayer.url)
							// 弹框形式，首次弹出 1，每次弹出 0
							if (!this.floatLayer.img_url) return
							var index_popwindow_count = this.$store.state.app.indexFloatLayerNum;						
							if (parseInt(this.floatLayer.number) >= 1) {												
								//缓存计数 == 弹出总数   禁止弹出						
								if(index_popwindow_count >= parseInt(this.floatLayer.number)){							
									this.floatLayer.is_show_type = false
								}else{
									this.floatLayer.is_show_type = true
								}							
							} else if (parseInt(this.floatLayer.number) == 0) {
								this.floatLayer.is_show_type = true							
							}
						}else{
							this.floatLayer.is_show_type = false	
						}
						
					}
				})
				.catch(err => err)
		},
		closeFloat() {
			var index_popwindow_count = this.$store.state.app.indexFloatLayerNum;
			if(parseInt(this.floatLayer.number) == 0){
				this.$store.commit("app/SET_FLOAT_LAYER", 0)
			}else if(parseInt(this.floatLayer.number) >= 1 && index_popwindow_count != parseInt(this.floatLayer.number)) {
				var count_num = index_popwindow_count + 1;
				this.$store.commit("app/SET_FLOAT_LAYER", count_num)
			}else if(index_popwindow_count == parseInt(this.floatLayer.number)){
				this.$store.commit("app/SET_FLOAT_LAYER", this.floatLayer.number)
			}
			this.floatLayer.is_show_type = false
			this.$forceUpdate()
			// this.$store.commit("app/SET_FLOAT_LAYER", -1)
		},
		// 监听滚动条
		handleScroll() {
			var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

			if (scrollTop >= 680) {
				this.isShow = true
			} else {
				this.isShow = false
			}
		},
		destroyed() {
			// 离开该页面需要移除这个监听的事件，不然会报错
			window.removeEventListener("scroll", this.handleScroll)
		},
		/**
		 * 默认搜索类型（goods/shop）
		 */
		getDefaultSearchWords() {
			apiDefaultSearchWords({}).then(res => {
				if (res.code == 0 && res.data.words) {
					this.defaultSearchWords = res.data.words
				}
			})
		},
		handleCommand(command) {
			this.searchType = command
		},
		search() {
			if (this.searchType == "goods") this.$router.push({
				path: "/list",
				query: {
					keyword: this.keyword
				}
			})
			else this.$router.push({
				path: "/street",
				query: {
					keyword: this.keyword
				}
			})
		},
		
		/**
		 * 获取分类
		 */
		
		goToMoreGame(){			
			this.$router.push({
				path: '/category'
			});
		},
		
		// 获取配置
		getCategoryConfig() {
			categoryConfig({
		
				})
				.then(res => {
					if (res.code == 0 && res.data) {
						this.categoryConfig = res.data;
						this.getTree(res.data);
					}
				})
				.catch(err => {
					this.$message.error(err.message);
				});
		},
		
		// 获取首页数据
		getHomeInfoData() {
			homeinfodata({
		
				})
				.then(res => {
					if (res.code == 0 && res.data) {
						this.homeinfodataArr = res.data;
					}
				})
				.catch(err => {
					this.$message.error(err.message);
				});
		},
		
		goYewuUrl(item){
			this.$router.push({
				path: "/web/cms/help-"+item.id,
				query: {
					
				}
			})
		},
		
		goToGoodsListPage(item){
			this.$router.push({
				path: "/list",
				query: {
					category_id:item.category_id,
					level:item.level
				}
			})
		},
		
		goGoodsDetail(goods_id){
			var href=window.location.host+"/web/sku-"+goods_id;
			window.open(href, "_blank");
		},
		
		/**
				 * 获取顶级账号列表
				 */
				goodsHomeList(){
					goodsHomeList({
					
						})
						.then(res => {
							if (res.code == 0 && res.data) {
								console.log(1111,res);
								this.goodsHomeListArr = res.data || [];
							}
						})
						.catch(err => {
							this.$message.error(err.message);
						});
				},
		

		
		getTree(categoryConfig) {
					tree({
						level: 3,
						template: 2
					})
						.then(res => {
							if (res.code == 0 && res.data) {
								var child_data=[];
								this.goodsCategoryTree = res.data || [];
								for(let i =0;i<this.goodsCategoryTree.length;i++) {
									if(this.goodsCategoryTree[i].child_list > 3) {
										this.isHide = true
									}
									if(this.goodsCategoryTree[i].child_list){
										for(var j=0;j<this.goodsCategoryTree[i].child_list.length;j++){
											if(this.goodsCategoryTree[i].child_list[j].is_hot==1){
												child_data.push(this.goodsCategoryTree[i].child_list[j]);
											}
										}
									}
																			
								}
								this.goodsCategoryAll=child_data;
							}
							console.log('各种分类',this.goodsCategoryTree,this.goodsCategoryAll);
						})
						.catch(err => {
							this.$message.error(err.message);
						});
				},
				
				handleClick(e) {
					this.zhanghao_fenlei_index=e.index;
				},
	}
}
